import React, { Component } from 'react';
import "./modal.css";

export class Spinner extends Component {

    render() {
        return (
            <div id="myModal" className="modal" style={{ display: this.props.isLoading ? 'block' : 'none' }}>
                <div className="modal-content">
                    <div className="spinner-border" />
                </div>
            </div>
        );
    }
}