import * as Api from '../utils/api';
const basePath = 'transaction';

export function getTransactions(data) {
    return Api.postData(`/api/${basePath}/getTransactions`, data).then(response => response.json());
}

export function getTransactionDetails(data) {
    return Api.postData(`/api/${basePath}/getTransactionDetails`, data).then(response => response.json());
}

export function deleteTransactions(data) {
    return Api.postData(`/api/${basePath}/deleteTransactions`, data).then(response => response.json());
}