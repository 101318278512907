import React, { Component } from 'react';
import * as TransactionApi from '../api/TransactionApi'
import * as LoginApi from '../api/LoginApi'
import { Spinner } from '../utils/spinner';
import toast from 'react-hot-toast';

export class Transactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            userHasAccess: false,
            transactions: [],
            deleteList: [],
            searchType: 'companyId',
            searchQuery: '',
            fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().substring(0, 10), //set to start of the month
            toDate: new Date().toISOString().substring(0, 10) //set to today's date
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.renderTransactionsTable = this.renderTransactionsTable.bind(this);
        this.getTransactions = this.getTransactions.bind(this);
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    componentDidMount() {
        LoginApi.getAuthenticationInfo()
            .then(data => {
                if (data.userId !== null && data.userName !== null)
                    this.setState({
                        userHasAccess: data
                    });
            })
            .catch(() => {
                this.setState({ isLoading: false });
                toast.error("Something went wrong.");
            })
    }

    getTransactions() {
        if (this.state.searchQuery === '') {
            toast.error("Search query can't be empty.")
            return;
        }

        const fromDate = new Date(this.state.fromDate);
        const toDate = new Date(this.state.toDate);

        if (toDate.getMonth() - fromDate.getMonth() + (12 * (toDate.getFullYear() - fromDate.getFullYear())) > 1) {
            toast.error("Query date can't be more than 1 month.")
            return;
        }

        let body = {
            companyQueryInfo: {
                searchType: this.state.searchType,
                searchQuery: this.state.searchQuery,
                fromDate: fromDate,
                toDate: toDate
            }
        };
        this.setState({ isLoading: true });
        TransactionApi.getTransactions(body)
            .then(resultInJson => {
                this.setState({ isLoading: false });
                var data = JSON.parse(resultInJson);
                if (data.ErrorCode === 0 && data.ReturnObject.Poss.length > 0) {
                    var transactions = data.ReturnObject.Poss;
                    for (let transaction of transactions) {
                        transaction.CreatedTime = new Date(transaction.CreatedTime);
                    }
                    this.setState({
                        transactions: transactions
                    });
                }
                else {
                    toast.error("No transactions found for the query.");
                }
            })
            .catch(() => {
                this.setState({ isLoading: false });
                toast.error("Something went wrong.");
            });
    }

    getTransactionDetails(transactionId) {
        let body = {
            transactionId: transactionId
        };
        this.setState({ isLoading: true });
        TransactionApi.getTransactionDetails(body)
            .then(resultInJson => {
                this.setState({ isLoading: false });
                var data = JSON.parse(resultInJson);
                this.showDetails(transactionId, data.ReturnObject.PosDtl);
            })
            .catch(() => {
                this.setState({ isLoading: false });
                toast.error("Something went wrong.");
            });
    }

    deleteTransactions(deleteList) {
        let body = {
            transactionIds: deleteList
        };
        this.setState({ isLoading: true });
        TransactionApi.deleteTransactions(body)
            .then(() => {
                this.setState({ isLoading: false });
                toast.success("Transactions deleted successfully");
                this.setState({
                    transactions: this.state.transactions.filter(item => {
                        return !deleteList.includes(item.Id);
                    }),
                    deleteList: [],
                });
            })
            .catch(() => {
                this.setState({ isLoading: false });
                toast.error("Something went wrong.");
            });
    }

    renderTransactionsTable(transactions) {
        return (
            <table className="table">
                <thead>
                    <tr height="80px">
                        <th scope="col">Created Time</th>
                        <th scope="col">Company Name</th>
                        <th scope="col">Outlet Name</th>
                        <th scope="col">Sales</th>
                        <th scope="col">Tax</th>
                        <th scope="col">Discount</th>
                        <th scope="col"></th>
                        <th scope="col" width="20%">
                            <div className="d-flex justify-content-center">
                                {this.state.deleteList.length > 0 && <button className="btn btn-danger" onClick={() => this.deleteTransactions(this.state.deleteList)}>Delete Selected</button>}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map(transaction => {
                        return (
                            <React.Fragment key={`${transaction.Id}-fragment`}>
                                <tr key={transaction.Id}>
                                    <td>{transaction.CreatedTime.toLocaleString()}</td>
                                    <td>{transaction.CompanyName}</td>
                                    <td>{transaction.OutletName}</td>
                                    <td>{transaction.Sales.toFixed(2)}</td>
                                    <td>{transaction.Tax.toFixed(2)}</td>
                                    <td>{transaction.Discount.toFixed(2)}</td>
                                    <td><button className="btn btn-primary btn-sm" type="button" onClick={() => this.getTransactionDetails(transaction.Id)}>Show/Hide Details</button></td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <input className="form-check-input" type="checkbox" onChange={(e) => this.handleCheckBoxChange(e.target.checked, transaction.Id)} />
                                        </div>
                                    </td>
                                </tr>
                                <tr className="d-none" id={transaction.Id} key={`${transaction.Id}-dtl`}>
                                    <td colSpan="8">
                                        <table className="table table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Item Guid</th>
                                                    <th scope="col">Item Name</th>
                                                    <th scope="col">Quantity</th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col">Tax</th>
                                                    <th scope="col">Discount</th>
                                                </tr>
                                            </thead>
                                            <tbody id={`tbody-${transaction.Id}`}>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </React.Fragment>
                        )
                    }
                    )}
                </tbody>
            </table>
        )
    }

    showDetails(id, posDtls) {
        var detailsElement = document.getElementById(id);

        if (detailsElement.classList.contains('d-none')) { //details hidden, getPosDtls and append to tbody
            const tbodyElement = document.getElementById(`tbody-${id}`)

            posDtls.forEach((posDtl, index) => {
                tbodyElement.innerHTML +=
                    `
                <tr key={${index}}}>
                  <td>${posDtl.ItemGuid}</td>
                  <td>${posDtl.ItemName}</td>
                  <td>${posDtl.Qty}</td>
                  <td>${posDtl.Total.toFixed(2)}</td>
                  <td>${posDtl.Tax.toFixed(2)}</td>
                  <td>${posDtl.Discount.toFixed(2)}</td>
                </tr>
            `;
            })

            detailsElement.classList.remove('d-none');
        }
        else //hide details
            detailsElement.classList.add('d-none');
    }

    handleCheckBoxChange(value, transactionId) {
        if (value === true)
            this.setState({ deleteList: [...this.state.deleteList, transactionId] })
        else {
            this.setState({
                deleteList: this.state.deleteList.filter(item => {
                    return item !== transactionId
                })
            });
        }
    }

    render() {
        return (
            <>
                <Spinner isLoading={this.state.isLoading} />
                {this.state.userHasAccess
                    ?
                    <>
                        <div className="row mb-3">
                            <div className="fs-5 fw-bold">Transactions Inquiry</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-3">
                                <label>From Date</label>
                                <input className="form-control" type="date" name="fromDate" value={this.state.fromDate} onChange={this.handleInputChange} />
                            </div>
                            <div className="col-3">
                                <label>To Date</label>
                                <input className="form-control" type="date" name="toDate" value={this.state.toDate} onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-6">
                                <label>Search Type</label>
                                <select className="form-select" aria-label="Default select example" name="searchType" value={this.state.searchType} onChange={this.handleInputChange}>
                                    <option value="companyId">Company ID (dbguid)</option>
                                    <option value="companyName">Company Name</option>
                                </select>
                            </div>
                            <div className="col-6">
                                <label>Search Query</label>
                                <input className="form-control" type="text" placeholder="Search Query" name="searchQuery" value={this.state.searchQuery} onChange={this.handleInputChange} />
                            </div>
                        </div>

                        <div className="d-flex justify-content-start">
                            <button className="btn btn-primary col-3" onClick={this.getTransactions}>Query Transactions</button>
                        </div>

                        {this.renderTransactionsTable(this.state.transactions)}
                        {this.state.transactions.length === 0 && 'Please filter/search transactions.'}
                    </>
                    : <div>User is not logged in or doesn't have access to this platform, please go back to <a href="/">Home Page</a> and login to another account.</div>
                }
            </>
        );
    }
}
