import React, { Component } from 'react';
import * as LoginApi from '../api/LoginApi'
import { Spinner } from '../utils/spinner';
import toast from 'react-hot-toast';

export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            email: '',
            password: '',
            userData: false,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.formSubmitHandler = this.formSubmitHandler.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        LoginApi.getAuthenticationInfo()
            .then(data => {
                if (data.userId !== null && data.userName !== null)
                    this.setState({
                        userData: data
                    });
            })
            .catch(() => {
                this.setState({ isLoading: false });
                toast.error("Something went wrong.");
            })
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    login() {
        let body = {
            email: this.state.email,
            password: this.state.password
        };
        this.setState({ isLoading: true })
        LoginApi.login(body)
            .then(data => {
                this.setState({ isLoading: false });
                if (data.returnObject !== null) {
                    toast.success('Login Successfully');
                    var user = {
                        userId: data.userId,
                        userName: data.userName
                    }
                    this.setState({
                        userData: user
                    });
                }
                else {
                    toast.error('User is not allowed to use this platform.');
                }
            })
            .catch(() => {
                this.setState({ isLoading: false });
                toast.error("Login credentials are incorrect or internal server error.");
            })
    }

    logout() {
        LoginApi.logout()
            .then(() => {
                toast.success('Logout Successfully');
                this.setState({
                    userData: null
                });
            })
            .catch(() => {
                this.setState({ isLoading: false });
                toast.error("Something went wrong.");
            })
    }

    formSubmitHandler(e) {
        e.preventDefault();
        this.setState({
            email: '',
            password: ''
        });
        this.login();
    }

    render() {
        return (
            <>
                <Spinner isLoading={this.state.isLoading} />
                <div className="mb-3 d-flex justify-content-center">
                    <h2>Home Page</h2>
                </div>
                {this.state.userData
                    ? <>
                        <div className="mb-5 d-flex justify-content-center">
                            <div className="fs-5">{`Welcome, ${this.state.userData.userName}`}</div>
                        </div>
                        <div className="mb-3 d-flex justify-content-center">
                            <div>User may choose to login to a different account after logging out.</div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button onClick={this.logout} className="btn btn-primary col-6">Logout</button>
                        </div>
                    </>
                    : <>
                        <div className="mb-3 d-flex justify-content-center">
                            <p>Please login to eDashboard account to get access to this internal platform.</p>
                        </div>
                        <form onSubmit={this.formSubmitHandler} >
                            <div className="mb-3 d-flex justify-content-center">
                                <div className="form-group col-6">
                                    <label>Email address</label>
                                    <input type="email" className="form-control" placeholder="Enter email" name="email" value={this.state.email} onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="mb-4 d-flex justify-content-center">
                                <div className="form-group col-6">
                                    <label>Password</label>
                                    <input type="password" className="form-control" placeholder="Enter password" name="password" value={this.state.password} onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="submit" className="btn btn-primary col-6">Login</button>
                            </div>
                        </form>
                    </>
                }
            </>
        );
    }
}
