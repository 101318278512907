export function getData(url) {
    return request(url)
        .then(handleResponse)
        .then(response => response.text())
        .then(text => {
            return text.length !== 0 ? JSON.parse(text) : text;
        });
}

export function postData(url, data) {
    return request(url, {
        method: 'POST',
        body: JSON.stringify(data)
    }).then(handleResponse);
}

export function putData(url, data) {
    return request(url, {
        method: 'PUT',
        body: JSON.stringify(data)
    }).then(handleResponse);
}

export function deleteData(url) {
    return request(url, {
        method: 'DELETE'
    }).then(handleResponse);
}

function request(url, options) {
    let fetchOptions = {
        ...options,
        headers: {           
            'Content-Type': 'application/json',           
        }
    };
   
    return fetch(url, fetchOptions);       
}

function handleResponse(response) {
    if (response.headers) {
        let version = response.headers.get('x-app-version');

        if (version) {
            window.GLOBAL_Version = version;
        }
    }

    if (response.ok) {
        return response;
    } else {
        return response.json()
            .then(data => {
                throw new ApiException(data.statusCode === 410 ? 410 : response.status, data.message);
            })
            .catch(error => {
                if (error.status) throw error;
                else throw new ApiException(response.status);
            });
    }
}

function ApiException(status, message) {
    this.status = status;
    this.message = message ? message : `Request failed with status ${status}`;
}

ApiException.prototype.toString = function () {
    return this.message;
};