import * as Api from '../utils/api';
const basePath = 'login';

export function login(data) {
    return Api.postData(`/api/${basePath}/login`, data).then(response => response.json());
}

export function logout(data) {
    return Api.postData(`/api/${basePath}/logout`, data).then(response => response.json());
}

export function getAuthenticationInfo(data) {
    return Api.getData(`/api/${basePath}/getAuthenticationInfo`, data);
}